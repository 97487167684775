<template>
  <v-container fluid>
    <v-toolbar
      color="transparent"
      :dark="this.$store.state.darkTheme ? true : false"
    >
      <v-toolbar-title>
        <h1
          v-if="!isMobile()"
          class="font-weight-light"
        >
          Accounting
        </h1>
        <h2
          v-if="isMobile()"
          class="font-weight-light"
        >
          Accounting
        </h2>
      </v-toolbar-title>
      <v-btn
        v-if="!isMobile()"
        color="gred"
        absolute
        right
        :href="'https://gricad-dashboards.univ-grenoble-alpes.fr/d/8nXIw5XMz/oar-clusters-project-stats?orgId=1&var-Cluster=All&var-Queue=besteffort&var-Queue=default&var-Project=All&var-User='+ this.$store.state.account.login"
        target="_blank"
      >
        My Accounting
        &thinsp;
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-toolbar>


  </v-container>
</template>

<script>
  // import store from '../store'
  import axios from 'axios'

  export default {
    data: () => ({
        // VARIABLE & V-MODEL
    }),
    computed: {
      styleDark () {
        return {
          'background-color': 'white',
          color: 'black',
        }
      },
      styleLight () {
        return {
          'background-color': '#031f30',
          color: 'white',
        }
      },
    },

    methods: {
      isMobile () {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },
      b64DecodeUnicode (str) {
        return decodeURIComponent(atob(str).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
      },
    },
  }
</script>

<style lang="scss">
  .btn-filter {
    min-width: 500px;
  }
</style>
